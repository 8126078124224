import { Hub } from '@shared/models/hub.model';
import { environment } from '@env/environment.hmr';
export class User {
  constructor(
    public email: string,
    public name?: string,
    public id?: string,
    public first_name?: string,
    public last_name?: string,
    public organization?: string,
    public role?: string,
    public Hubs?: Hub[],
    public preferences?: { defaultHub?: string, kalturaCustomId?: string, ssoUserId?: string },
    public HubsUsers?: any,
    public intercom_hash?: string,
    public feature_usage?: any
  ) { }
}

/**
 * Companion functions so we don't have to instantiate new Users
 */
export function isAdmin(user: User): boolean {
  return user.Hubs.find(hub => hub.HubsUsers.role === 'admin') !== undefined;
}

export function isInImlab(user: User): boolean {
  return user.Hubs.find(hub => hub.id === environment.imlabHubId) !== undefined;
}

export function isInOtherlab(user: User): boolean {
  return user.Hubs.find(hub => hub.id !== environment.imlabHubId) !== undefined && user.Hubs.length >= 1;
}

export function getHubListAndRole(user: User): { hub: string, role: string, hubType: string, plan: string } {
  const titleList = user.Hubs.map(hub => hub.urlTitle).join(', ');
  const roleList = user.Hubs.map(hub => hub.HubsUsers.role).join(', ');
  const hubTypeList = user.Hubs.map(hub => hub.hub_type).join(', ');
  const planList = user.Hubs.map(hub => hub.plan).join(', ');
  return { hub: titleList, role: roleList, hubType: hubTypeList, plan: planList };
}
